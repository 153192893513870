import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ca provisioner webhook`}</strong>{` -- create and manage webhooks for a provisioner`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca provisioner webhook <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca provisioner webhook`}</strong>{` command group provides facilities for managing the webhooks attached to a provisioner`}</p>
    <p>{`Administrators can attach webhooks to provisioners to retrieve additional data that will be available when rendering certificate templates.
Webhooks can also be used to disallow signing certificates for unknown entities.`}</p>
    <p>{`Any data returned from the webhook server will be added to the template context under the path "Webhooks.`}<inlineCode parentName="p">{`name`}</inlineCode>{`".
Implementations of webhook servers must conform to the step-ca documentation at `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-ca/templates"
      }}>{`https://smallstep.com/docs/step-ca/templates`}</a>{` for parsing and verifying request bodies and forming valid response bodies.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Add a new webhook to a provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner webhook add my_provisioner my_webhook --url https://example.com
`}</code></pre>
    <p>{`Change a webhook's url:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner webhook update my_provisioner my_webhook --url https://example.com
`}</code></pre>
    <p>{`Remove a webhook:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner webhook remove my_provisioner my_webhook
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "add/"
              }}>{`add`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`add a webhook to a provisioner`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "update/"
              }}>{`update`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`update a webhook attached to a provisioner`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "remove/"
              }}>{`remove`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`remove a webhook from a provisioner`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      